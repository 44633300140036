import React, { useEffect, useState } from 'react'
import { HelpModalWithoutTrigger } from '@ally/shared-help-modal'
import { useLobs } from '../hooks/use-lobs'
import { FeatureFlag, useFeatureFlag } from '../hooks/use-feature-flag'
import { useServices } from '../services/ServicesProvider'

const HelpModal: React.FC = () => {
  const { header } = useServices()

  const { bank: hasBankRelationship } = useLobs()
  const service = 'YEXT'
  const DebounceLimit = useFeatureFlag(FeatureFlag.DebounceLimit, 300)

  const [headerState, setHeaderState] = useState(header.getState())

  useEffect(() => {
    const subscription = header.state$.subscribe(state => {
      setHeaderState(state)
    })
    return subscription.unsubscribe
  }, [])

  return (
    <HelpModalWithoutTrigger
      service={service}
      lob="spog"
      showCoBrowse={hasBankRelationship}
      open={headerState.helpModalStatus === 'Open'}
      defaultCategory={headerState.helpModalDefaultCategory?.toString()}
      onOpenChange={(modalState: boolean): void => {
        if (modalState) header.openHelpModal()
        else header.closeHelpModal()
      }}
      debounceLimit={DebounceLimit}
    />
  )
}

export { HelpModal }
