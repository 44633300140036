import {
  App,
  AppSchema,
  Federator,
  FederatorConfig,
  RouteChangeError,
} from '@ally/federator'
import {
  RefObject,
  createRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'

import { UserRegistrationStates } from '../hooks'
import { Route, useBootstrap } from '../providers'
import {
  trackRemoteError,
  trackRemoteMount,
  trackRemoteMounted,
  trackRemoteUnmount,
  trackRemoteUnmounted,
} from '../tracking'
import log from '../whisper'
import { useHostServices } from './use-host-services'
import { useRemotes } from './use-remotes'
import {
  contentFederator,
  debugFederator,
  dropRemoteStyles,
  getRemoteLogger,
  headerFederator,
  injectRemoteStyles,
} from './utils'

type Hooks = FederatorConfig['hooks']
export interface FederationProps extends Hooks {
  userRegistrationStates: UserRegistrationStates
  remoteFilter?: (schema: Route) => boolean
}

export interface FederationValues {
  federatedRef: RefObject<HTMLDivElement>
  isTransitioning: boolean
  isFederatedRoute: boolean
  remotes: Record<string, AppSchema>
}

export function getUseFederation(federator: Federator) {
  return (config: FederationProps): FederationValues => {
    const [[isFederatedRoute, isTransitioning], setState] = useState([
      false,
      true,
    ])
    const done = useCallback((): void => setState([true, false]), [setState])

    const federatedRef = createRef<HTMLDivElement>()
    const remotes = useRemotes(config.remoteFilter)
    useEffect(() => federator.setApps(remotes), [remotes])

    const { isBootstrapping } = useBootstrap()

    // Make sure we are no longer bootstrapping and there are remotes loaded
    const isReady = !isBootstrapping && Object.keys(federator.apps).length > 0

    useEffect(() => {
      if (isReady) {
        federator.listen(federatedRef.current as HTMLDivElement)
      }

      return (): void => {
        federator.unlisten()
      }
    }, [isReady])

    useEffect(() => (): void => federator.teardown(), [])

    const remoteLogger = useMemo(
      () => getRemoteLogger(federator.mounted ?? ''),
      [federator.mounted],
    )

    const hostServices = useHostServices({
      logger: remoteLogger,
      userRegistrationStates: config.userRegistrationStates,
      done,
    })

    const dispatchServices = useCallback(() => {
      if (hostServices && federator.mounted) {
        log.info({ message: `Dispatched HostServices to ${federator.mounted}` })
        federator.channel.send(hostServices)
      }
    }, [hostServices, federator.mounted])

    useEffect(() => dispatchServices(), [dispatchServices])

    useEffect(() => {
      federator.setHooks({
        onMount: (app: App) => {
          injectRemoteStyles(app)
          config.onMount?.(app)
          trackRemoteMount(app, remotes)
        },
        onMounted: (app: App) => {
          config.onMounted?.(app)
          trackRemoteMounted(app, remotes)
        },
        onUnmount: (app: App) => {
          config.onUnmount?.(app)
          trackRemoteUnmount(app, remotes)
        },
        onUnmounted: (app: App): void => {
          dropRemoteStyles(app)
          config.onUnmounted?.(app)
          trackRemoteUnmounted(app, remotes)
        },
        onRouteChangeEnd: (app: App | null) => {
          setState([!!app, !!app])
          dispatchServices()
          config.onRouteChangeEnd?.(app)
        },
        onRouteChangeStart: () => {
          setState([false, true])
          config.onRouteChangeStart?.()
        },
        onRouteChangeError: (info: RouteChangeError) => {
          setState([false, false])
          const { remoteId: id } = info
          log.error({
            message: [
              `[REMOTES (${id})] Error while handling route change:`,
              info.error.stack,
            ],
          })
          config.onRouteChangeError?.(info)
          trackRemoteError({ id }, remotes, {
            errorMessage: info.error.message,
          })
        },
      })
    }, [dispatchServices, remotes])

    return {
      federatedRef,
      isFederatedRoute,
      isTransitioning,
      remotes,
    }
  }
}

export const useContentFederation = getUseFederation(contentFederator)

export const useHeaderFederation = getUseFederation(headerFederator)

export const useDebugFederation = getUseFederation(debugFederator)
