import { HostServices, RemoteConfig } from '@ally-financial/next-core'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  useBootstrap,
  useSession,
  useHostData,
  useAnalytics,
  useMortgageRedirect,
} from '../providers'
import { UserRegistrationStates } from '../hooks/use-register-user'
import { getHostServices, HostServicesMessage } from './utils'
import { useServices } from '../services/ServicesProvider'
import { useService } from '../services/useService'

export interface UseHostServicesProps {
  logger: HostServices['log']
  userRegistrationStates: UserRegistrationStates
  done: () => void
}

export function useHostServices({
  logger,
  userRegistrationStates,
  done,
}: UseHostServicesProps): HostServicesMessage | null {
  const history = useHistory()
  const session = useSession()
  const hostData = useHostData()
  const analytics = useAnalytics()

  const { services } = useBootstrap()
  const { redirectToMortgage } = useMortgageRedirect()
  const { autoLogin, header } = useServices()

  // Get Service states to use as triggers for re-calculating hostServices
  // This is necessary since the services will never "change" at the surface level
  const { state: headerState } = useService(header)

  const hostServices = useMemo(() => {
    if (!services) return null
    return getHostServices({
      boot: services,
      done,
      actionBar: {
        notifications: {
          /**
           * @usage `notifications.set` is used by remotes to populate the notifications
           * dropdown (iBubble) with messages.
           *
           * In a remote, `notifications.set` can be consumed in the following way:
           *
           * const { actionBar } = useHostServices()
           * const [isNotifSet, setIsNotifSet] = React.useState(false)
           *
           * React.useEffect(() => {
           *  if (actionBar && !isNotifSet) {
           *    actionBar.notifications.set([
           *     {
           *      type: 'REMOTE',
           *      innerContent: <TextBody tag="p" size="md">Notifification Text</TextBody>,
           *     },
           *    ])
           *   setIsNotifSet(true)
           *  }
           * }, [actionBar])
           *
           * @type {(remoteNotifications: RemoteConfig[]) => void}
           */
          set: header.setRemoteNotifications,
          get: (): RemoteConfig[] => {
            return header.getState().remoteNotifications
          },
        },
      },
      logger,
      session,
      hostData,
      analytics,
      userRegistrationStates,
      browserHistory: history,
      redirectToMortgage,
      autoLogin,
      header,
    })
  }, [
    session,
    hostData,
    services,
    analytics,
    userRegistrationStates,
    history,
    redirectToMortgage,
    autoLogin,
    headerState,
  ])

  return hostServices
}
